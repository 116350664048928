
import { computed, defineComponent, PropType, reactive } from "vue";
import moment, { Moment } from "moment-timezone";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import { CalendarSchedule } from "@/types/common";

type ReservedDate = {
  openedCount: number;
  reservationList: CalendarSchedule[];
};
export default defineComponent({
  name: "MonthDateCell",
  components: { MoreVertiIcon, PlusIcon },
  props: {
    date: {
      type: [Object, null] as PropType<Moment>,
      required: true,
    },
    scheduleInfo: {
      type: Object as PropType<ReservedDate>,
      default: null,
    },
    borderBottom: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["toggleWeekCalendar"],
  setup(props, { emit }) {
    const state = reactive({
      day: computed(() => {
        return props.date ? props.date.date() : "";
      }),
      isSunday: computed(() => {
        return props.date.day() === 0;
      }),
      isToday: computed(() => {
        return (
          props.date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        );
      }),
      reservedLoopCount: computed(() => {
        return props.scheduleInfo.reservationList.length > 3
          ? 3
          : props.scheduleInfo.reservationList.length;
      }),
    });

    const actions = {
      goToDetail: () => {
        emit("toggleWeekCalendar");
      },
    };

    return { state, actions };
  },
});
