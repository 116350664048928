
import { computed, defineComponent, PropType, reactive } from "vue";
import moment from "moment-timezone";
import { useStore } from "vuex";

export default defineComponent({
  name: "YoilCell",
  props: {
    yoilIndex: {
      type: Number as PropType<number>,
      required: true,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      yoil: computed(() => {
        return moment().day(props.yoilIndex).locale(state.locale).format("ddd");
      }),
      locale: computed(() => {
        return store.getters["locale/locale"];
      }),
    });

    return { state };
  },
});
