import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3814c64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "week-time__cell" }
const _hoisted_2 = {
  key: 1,
  class: "opened"
}
const _hoisted_3 = {
  key: 2,
  class: "reserved"
}
const _hoisted_4 = {
  key: 0,
  class: "reserved-time"
}
const _hoisted_5 = {
  key: 1,
  class: "user"
}
const _hoisted_6 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["grid-item", {
        'last-reserved': _ctx.state.isReservedLastCell,
        'start-reserved': _ctx.state.isReservedStartCell,
        'between-reserved': _ctx.state.isReservedCurrentCell,
      }])
    }, [
      (!_ctx.opened && !_ctx.reservationItem)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["inner", { selected: _ctx.selected }])
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.opened && !_ctx.reservationItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      (!!_ctx.reservationItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (
            _ctx.reservationItem.startAt.format('HH:mm') === _ctx.startAt.format('HH:mm')
          )
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.state.reservedTime), 1))
              : _createCommentVNode("", true),
            (
            _ctx.reservationItem.startAt.format('HH:mm') === _ctx.startAt.format('HH:mm')
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_avatar, {
                    "avatar-img": _ctx.reservationItem.scheduleUser.user?.avatar,
                    size: 16
                  }, null, 8, ["avatar-img"]),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.reservationItem.scheduleUser.user?.name), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}