
import { computed, defineComponent, PropType, reactive } from "vue";
import { ReservedSchedule } from "@/types/lectures";
import moment, { Moment } from "moment-timezone";
import Avatar from "@/components/console/avatars/Avatar.vue";

export default defineComponent({
  name: "WeekTimeCell",
  components: { Avatar },
  props: {
    startAt: {
      type: Object as PropType<Moment>,
      required: true,
    },
    endAt: {
      type: Object as PropType<Moment>,
      required: true,
    },
    opened: {
      type: Boolean,
      required: false,
    },
    selected: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    reservationItem: {
      type: Object as PropType<ReservedSchedule>,
      default: null,
    },
  },
  setup(props) {
    const state = reactive({
      reservedTime: computed(() => {
        if (props.reservationItem) {
          return `${props.reservationItem.startAt.format(
            "HH:mm"
          )} - ${props.reservationItem.endAt.format("HH:mm")}`;
        }
        return "";
      }),
      isReservedStartCell: computed(() => {
        if (props.reservationItem) {
          return moment(props.reservationItem.startAt).isBetween(
            props.startAt,
            props.endAt,
            undefined,
            "[)"
          );
        }
        return false;
      }),
      isReservedCurrentCell: computed(() => {
        if (props.reservationItem) {
          return moment(props.startAt).isBetween(
            props.reservationItem.startAt,
            props.reservationItem.endAt,
            undefined,
            "()"
          );
        }
        return false;
      }),
      isReservedLastCell: computed(() => {
        if (props.reservationItem) {
          return moment(props.reservationItem.endAt).isBetween(
            props.startAt,
            props.endAt,
            undefined,
            "(]"
          );
        }
        return false;
      }),
    });

    return { state };
  },
});
