
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
  watch,
  ref,
} from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import moment from "moment-timezone";
import { Moment } from "moment-timezone";
import { CalendarSchedule } from "@/types/common";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import MonthDateCell from "@/components/console/calendar/MonthDateCell/MonthDateCell.vue";
import YoilCell from "@/components/console/calendar/YoilCell/YoilCell.vue";
import WeekDateCell from "@/components/console/calendar/WeekDateCell/WeekDateCell.vue";
import WeekTimeCell from "@/components/console/calendar/WeekTimeCell/WeekTimeCell.vue";
import { ReservedSchedule } from "@/types/lectures";
import TimeUnitCell from "@/components/console/calendar/TimeUnitCell/TimeUnitCell.vue";
import Tooltip from "@/components/console/tooltip/Tooltip.vue";
import helper from "@/helper";

type CalendarType = "M" | "W";
type TimeGrid = {
  startAt: Moment;
  endAt: Moment;
};
type ReservedDate = {
  reservationList: CalendarSchedule[];
  openedCount: number;
};
type MomentArr = Moment[];
type TimeList = TimeGrid[];
type ReserveArr = ReservedSchedule[];
type OpenedArr = boolean[];
type ReservedListMonth = ReservedDate[];

export default defineComponent({
  name: "Calendar",
  components: {
    TimeUnitCell,
    WeekTimeCell,
    WeekDateCell,
    YoilCell,
    MonthDateCell,
    ArrowIcon,
    ButtonText,
    CardBasic,
    ButtonBasic,
    Tooltip,
  },
  props: {
    calendarType: {
      type: String as PropType<CalendarType>,
      default: "M",
    },
    schedules: {
      type: Array as PropType<CalendarSchedule[]>,
      default: () => {
        return [];
      },
    },
    reservations: {
      type: Array as PropType<ReservedSchedule[]>,
      default: () => {
        return [];
      },
    },
  },
  emits: [
    "getSchedules",
    "openSchedules",
    "deleteSchedule",
    "setCalendarType",
    "cancelReserve",
  ],
  setup(props, { emit }) {
    const monthType = "M";
    const weekType = "W";
    const yoil: number[] = [0, 1, 2, 3, 4, 5, 6];
    const timeUnits = [
      "00:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
      "24:00",
    ];

    const timeTableWrapperElement = ref(null);

    const state = reactive({
      completeCalendarSet: false,
      completeWeekCalendar: false,
      calendarType: props.calendarType as CalendarType,
      monthTypeCalendar: [
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
      ] as MomentArr[],
      monthSchedules: [
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
      ] as ReservedListMonth[],
      weekTypeYoil: [null, null, null, null, null, null, null] as MomentArr,
      weekTypeCalendar: [] as TimeList[],
      openedSchedule: [] as OpenedArr[], // open 되어있는 예약 스케쥴
      reservedSchedules: [] as ReserveArr[],
      month: computed(() => {
        return moment().add(state.monthIndex, "months").month() + 1; // note moment.month() 는 index 0 부터시작, 현재 월을 얻으려면 +1 해야함.
      }),
      year: computed(() => {
        return moment().add(state.monthIndex, "months").year(); // note moment.month() 는 index 0 부터시작, 현재 월을 얻으려면 +1 해야함.
      }),
      monthIndex: 0,
      weekIndex: 0,
      dateIndex: moment().startOf("month").day() - moment().day(),
      dayIndex: computed(() => {
        return moment().add(state.dateIndex, "days").day();
      }),
      isMouseDown: false,
      selectRangeStart: null as Moment,
      selectRangeEnd: null as Moment,
      isSelectedRange: computed(() => {
        return !!state.selectRangeStart && !!state.selectRangeEnd;
      }),
      lastSelectedTimeGrid: null as TimeGrid,
      isPc: false,
      date: computed(() => {
        let date = `${state.year}-${state.month}`;
        return moment(date);
      }),
      selectedDate: 0,
      selectedDay: computed(() => {
        return `${moment(state.selectedDate).format("YYYY")}년 ${moment(
          state.selectedDate
        ).format("M")}월 ${moment(state.selectedDate).format("D")}일`;
      }),
      calendarHeaderDate: computed(() => {
        let index;
        let isFirstDay = false;
        let isChangedYear = false;
        for (index = 1; index < 7; index++) {
          let firstDay = Number(moment(state.weekTypeYoil[index]).format("D"));
          if (firstDay === 1) {
            isFirstDay = true;
            if (Number(moment(state.weekTypeYoil[index]).format("M")) === 1) {
              isChangedYear = true;
            }
            break;
          }
        }

        if (isFirstDay) {
          if (isChangedYear) {
            return `${Number(state.year) - 1}년 12월 -  ${Number(
              state.year
            )}년 ${state.month}월`;
          } else {
            return `${state.year}년  ${Number(state.month) - 1}월 - ${
              state.month
            }월`;
          }
        } else {
          return `${state.year}년 ${state.month}월`;
        }
      }),
    });

    onBeforeMount(() => {
      setMonthCalendarGrid(0);
      setWeekCalendarGrid();
      setWeekScheduleGrid();
      setMonthScheduleGrid(props.schedules, props.reservations);
      state.completeCalendarSet = true;
      state.isPc = !helper.isTabletSize();
    });

    watch(
      () => [props.schedules, props.reservations],
      (updatedData) => {
        let schedules = updatedData[0];
        let reservations = updatedData[1];

        if (state.calendarType === weekType) {
          setWeekSchedules(schedules, reservations);
        } else {
          setMonthScheduleGrid(schedules, reservations);
        }
      },
      { deep: true }
    );

    // note 주간 달력에서 테이블에서, 스크롤을 7시 테이블로 이동하는 로직
    // TODO 로직 실행시키는 방법 개선
    watch(
      () => timeTableWrapperElement.value,
      (element) => {
        if (element) {
          element.scrollTop = helper.isTabletSize() ? 820 : 890;
        }
      }
    );

    // note 주간달력 데이터 포맷 만드는 메서드
    const setWeekCalendarGrid = (): void => {
      for (let i = 0; i < 7; i++) {
        state.weekTypeCalendar.push(Array(48).fill(null));
      }
    };
    // note 주간달력 스케쥴 데이터 포맷 만드는 메서드
    const setWeekScheduleGrid = (): void => {
      for (let i = 0; i < 7; i++) {
        state.openedSchedule.push(Array(48).fill(false));
        state.reservedSchedules.push(Array(48).fill(null));
      }
    };

    // note 월 캘린더를 만드는 함수
    const setMonthCalendarGrid = (monthIndex: number): void => {
      const beforeMonthLastDate = moment()
        .add(monthIndex - 1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");

      const startDayOfMonth = moment()
        .add(monthIndex, "months")
        .startOf("month")
        .day(); // note 현재 달의 첫일자의 요일 (0:일 ~ 6:토)

      const startDateFormat = moment()
        .add(monthIndex, "months")
        .startOf("month")
        .format("YYYY-MM-DD");

      let count = 0;
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < startDayOfMonth) {
            state.monthTypeCalendar[i][startDayOfMonth - j - 1] = moment(
              beforeMonthLastDate
            ).add(-j, "days");
          } else {
            state.monthTypeCalendar[i][j] = moment(startDateFormat).add(
              count,
              "days"
            );
            count++;
          }
        }
      }
    };

    // note 월 캘린더에 데이터 표시(open, reservation)
    const setMonthScheduleGrid = (schedules, reservations) => {
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 7; j++) {
          const openedList = schedules.filter((item) => {
            return (
              item.startAt.format("YYYY-MM-DD") ===
              state.monthTypeCalendar[i][j].format("YYYY-MM-DD")
            );
          });

          const dayReservedList = reservations.filter((item) => {
            return (
              item.startAt.format("YYYY-MM-DD") ===
              state.monthTypeCalendar[i][j].format("YYYY-MM-DD")
            );
          });

          if (openedList.length > 0) {
            state.monthSchedules[i][j] = {
              openedCount: openedList.length,
              reservationList: dayReservedList,
            };
          } else {
            state.monthSchedules[i][j] = null;
          }
        }
      }
    };

    // note 주간 캘린더 time grid 세팅
    const setWeekCalendar = (weekIndex: number): void => {
      const weekStartDateFormat = moment()
        .add(weekIndex, "weeks")
        .startOf("week")
        .format("YYYY-MM-DD HH:mm");

      for (let i = 0; i < 7; i++) {
        state.weekTypeYoil[i] = moment(weekStartDateFormat).add(i, "days");

        for (let j = 0; j < 48; j++) {
          const startAt = moment(
            state.weekTypeYoil[i].format("YYYY-MM-DD HH:mm")
          ).add(j * 30, "minutes");
          const endAt = moment(
            state.weekTypeYoil[i].format("YYYY-MM-DD HH:mm")
          ).add((j + 1) * 30, "minutes");
          state.weekTypeCalendar[i][j] = {
            startAt: startAt,
            endAt: endAt,
          };
        }
      }
    };

    const updateDateIndex = () => {
      return (
        moment(`${state.year}-${state.month}`).startOf("month").day() -
        moment().day()
      );
    };

    //note 캘린더의 요일을 선택했을 때 선택된 날짜의 월로 변경
    const changeMonthMtype = (date: Moment) => {
      let diffMonths = Number(moment(date).format("M")) - state.month;
      let diffYears = Number(moment(date).format("YYYY")) - state.year;

      if (diffYears === 0) {
        return diffMonths;
      } else {
        return 0;
      }
    };

    const updateWeekIndex = (date: Moment) => {
      let dateValue = moment(date);
      let theStartDateOfSelectedWeek = dateValue.startOf("week");

      return Math.ceil(
        theStartDateOfSelectedWeek.diff(moment(), "weeks", true)
      );
    };

    const changedMonth = () => {
      //note 주간 캘린더의 토요일 기준으로,  '월'이 변경되는 경우 캘린더의 '월'을 변경하는 로직.
      let saturday = {
        year: Number(state.weekTypeYoil[6].format("YYYY")),
        month: Number(state.weekTypeYoil[6].format("M")),
      };

      let thursday = {
        year: Number(state.weekTypeYoil[4].format("YYYY")),
        month: Number(state.weekTypeYoil[4].format("M")),
      };

      let diffYears = state.year !== saturday.year;
      let diffMonths = state.month !== saturday.month;

      //note 년도는 같고 월만 바뀐 경우
      if (!diffYears && diffMonths) {
        return 1;
      } else if (diffYears) {
        //note 년도가 달라진 경우
        return 1;
      } else {
        return 0;
      }
    };

    const decreaseMonth = () => {
      state.monthIndex -= changedMonth();
    };

    const increaseMonth = () => {
      state.monthIndex += changedMonth();
    };

    // note 주간 캘린더 time grid 에 스케쥴 바인딩
    const setWeekSchedules = (schedules: CalendarSchedule[], reservations) => {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 48; j++) {
          let schedule = findSchedule(schedules, state.weekTypeCalendar[i][j]);
          let reservationItem = findReservedTimeGrid(
            reservations,
            state.weekTypeCalendar[i][j]
          );

          state.openedSchedule[i][j] = !!schedule;
          state.reservedSchedules[i][j] = reservationItem;
        }
      }
    };

    //  note 주간달력 time grid 가 예약된 time grid 인지 확인하고, 예약 데이터를 반환해주는 메서드
    const findReservedTimeGrid = (
      reservations: ReservedSchedule[],
      timeGrid: TimeGrid
    ) => {
      return reservations.find((item) => {
        // 예약 시간 기준 시작값, 끝값, 사잇값 을 비교해서 찾는다.
        return (
          moment(item.startAt).isBetween(
            timeGrid.startAt,
            timeGrid.endAt,
            undefined,
            "[)"
          ) ||
          moment(item.endAt).isBetween(
            timeGrid.startAt,
            timeGrid.endAt,
            undefined,
            "()"
          ) ||
          moment(timeGrid.startAt).isBetween(
            item.startAt,
            item.endAt,
            undefined,
            "()"
          )
        );
      });
    };

    //  note 주간달력 time grid 가 open 된 time grid 인지 확인하고, 예약 데이터를 반환해주는 메서드
    const findSchedule = (
      schedules: CalendarSchedule[],
      targetSchedule: TimeGrid
    ) => {
      return schedules.find((item) => {
        return (
          targetSchedule.startAt.format("YYYY-MM-DD HH:mm") ===
          item.startAt.format("YYYY-MM-DD HH:mm")
        );
      });
    };

    // note 모든 index 초기화
    const resetIndex = (): void => {
      state.weekIndex = 0;
      state.monthIndex = 0;
      // state.dateIndex = 0;
      state.dateIndex = moment().startOf("month").day() - moment().day();
    };

    // note 주간 캘린더에서 time grid 가 선택되었는지 여부를 반환해주는 메서드
    const isSelectedDate = (timeGrid: TimeGrid): boolean => {
      if (state.isSelectedRange) {
        return (
          state.selectRangeStart.diff(timeGrid.startAt) <= 0 &&
          state.selectRangeEnd.diff(timeGrid.endAt) >= 0
        );
      } else {
        return false;
      }
    };

    // note 주간 캘린더에서 선택된 time grid를 선택 안된 상태로 만드는 메서드
    const resetSelectedRange = () => {
      state.selectRangeStart = null;
      state.selectRangeEnd = null;
      state.lastSelectedTimeGrid = null;
    };

    // note weekIndex, monthIndex 로 from date, to date 를 정해서 부모에게 schedule 데이터를 요청하는 메서드
    const syncSchedules = async () => {
      let fromDate: Moment;
      let toDate: Moment;
      if (state.calendarType === weekType) {
        fromDate = moment().add(state.weekIndex, "weeks").startOf("week");
        toDate = moment().add(state.weekIndex, "weeks").endOf("week");
      } else {
        //note 달력에 예약목록을 가져오는 로직 startOf, ednOf 는 7로 고정되어 있었으나, 다음달의 달력의 예약목록이 나오지 않아 추가함 한주 더 추가함.
        fromDate = moment()
          .add(state.monthIndex, "months")
          .startOf("month")
          .add(-7, "days");
        toDate = moment()
          .add(state.monthIndex, "months")
          .endOf("month")
          .add(14, "days");
      }

      await emit("getSchedules", fromDate, toDate);
    };

    // note monthIndex, weekIndex, dayIndex 로 dateIndex 를 정하는 메서드
    const setDateIndex = () => {
      let date = moment()
        .add(state.monthIndex, "months")
        .add(state.weekIndex, "weeks")
        .add(state.dateIndex, "days");

      state.dateIndex = date
        .startOf("day")
        .diff(moment().startOf("day"), "days");
    };

    const updateCalendarHeaderDate = (dateParameter: {
      date?: Moment;
      weekIndex?: number;
    }) => {
      let dateVal: Moment;
      if (dateParameter.date) {
        let dateVal = dateParameter.date;
        state.selectedDate = dateVal;
      }
      if (dateParameter.weekIndex) {
        let dateVal = moment(state.selectedDate).add(
          dateParameter.weekIndex,
          "weeks"
        );
        state.selectedDate = dateVal;
      }
    };

    const actions = {
      prevIndex: () => {
        // 주간캘린더의 경우
        if (state.calendarType === weekType) {
          state.weekIndex--;
          setWeekCalendar(state.weekIndex);
          updateCalendarHeaderDate({ weekIndex: -1 });
          decreaseMonth();
        } else {
          state.monthIndex--;
          state.weekIndex = 0;
          //note dateIndex 초기화
          state.dateIndex = updateDateIndex();
          setMonthCalendarGrid(state.monthIndex);
        }
        syncSchedules();
        // setDateIndex();
      },
      resetCurrentDate: () => {
        resetIndex();
        if (state.calendarType === weekType) {
          setWeekCalendar(state.weekIndex);
        } else {
          setMonthCalendarGrid(state.monthIndex);
        }
        syncSchedules();
      },
      nextIndex: () => {
        // 주간캘린더의 경우
        if (state.calendarType === weekType) {
          state.weekIndex++;
          setWeekCalendar(state.weekIndex);
          updateCalendarHeaderDate({ weekIndex: 1 });
          increaseMonth();
        } else {
          state.monthIndex++;
          state.weekIndex = 0;
          //note dateIndex 초기화
          state.dateIndex = updateDateIndex();
          // state.dateIndex = 0;
          setMonthCalendarGrid(state.monthIndex);
        }
        syncSchedules();
        // setDateIndex();
      },
      setMonthType: async (): Promise<void> => {
        state.completeCalendarSet = false;
        state.calendarType = monthType;
        // note 주간 초기화
        state.weekIndex = 0;
        // note 월간 캘린더에서 날짜를 선택하면 선택한 날짜의 위치의 인덱스만큼 계산하기 때문에 주간 달력으로 가면 계속 고정되어 있는 날이 생김.
        state.dateIndex = updateDateIndex();
        await setMonthCalendarGrid(state.monthIndex);
        await syncSchedules();
        state.completeCalendarSet = true;
        emit("setCalendarType", monthType);
      },
      setWeekType: async (): Promise<void> => {
        state.completeCalendarSet = false;
        state.calendarType = weekType;
        // resetIndex();
        state.weekIndex += updateWeekIndex(state.date);
        updateCalendarHeaderDate({ date: state.date });
        await setWeekCalendar(state.weekIndex);
        await syncSchedules();
        // note 초기화
        state.completeCalendarSet = true;
        emit("setCalendarType", weekType);
      },
      // 주간 캘린더의 데이트 설정
      setWeekCalendarForDate: (date: Moment) => {
        state.monthIndex += changeMonthMtype(date);
        state.weekIndex += updateWeekIndex(date);
        updateCalendarHeaderDate({ date });
        state.calendarType = weekType;
        state.dateIndex = date
          .startOf("day")
          .diff(moment().startOf("day"), "days");
        setWeekCalendar(state.weekIndex);

        syncSchedules();
        emit("setCalendarType", weekType);
      },
      setMobileWeekCalendar: (date: Moment) => {
        updateCalendarHeaderDate({ date });
        state.selectedDate = date;
        state.dateIndex = date
          .startOf("day")
          .diff(moment().startOf("day"), "days");
      },
      onMousedownEvent: (timeGrid: TimeGrid, row, col) => {
        if (state.openedSchedule[row][col]) {
          return;
        }
        if (!isSelectedDate(timeGrid)) {
          state.isMouseDown = true;
          state.selectRangeStart = timeGrid.startAt;
          state.selectRangeEnd = timeGrid.endAt;
          state.lastSelectedTimeGrid = timeGrid;
        }
      },
      onMouseUpEvent: async () => {
        if (state.isMouseDown) {
          state.isMouseDown = false;
          await emit(
            "openSchedules",
            state.selectRangeStart,
            state.selectRangeEnd,
            state.weekIndex
          );
          resetSelectedRange();
        }
      },
      onMouseEnterEvent: (
        timeGrid: TimeGrid,
        row: number,
        col: number
      ): void => {
        if (state.isMouseDown) {
          if (
            state.openedSchedule[row][col] ||
            timeGrid.startAt.date() !==
              state.lastSelectedTimeGrid.startAt.date()
          ) {
            state.isMouseDown = false;
            emit(
              "openSchedules",
              state.selectRangeStart,
              state.selectRangeEnd,
              state.weekIndex
            );
            resetSelectedRange();
            return;
          }

          let upDirection =
            timeGrid.startAt.diff(state.lastSelectedTimeGrid.startAt) < 0;

          if (isSelectedDate(timeGrid)) {
            if (upDirection) {
              state.selectRangeEnd = timeGrid.endAt;
            } else {
              state.selectRangeStart = timeGrid.startAt;
            }
          } else {
            if (upDirection) {
              state.selectRangeStart = timeGrid.startAt;
            } else {
              state.selectRangeEnd = timeGrid.endAt;
            }
          }

          state.lastSelectedTimeGrid = timeGrid;
        }
      },
      onMouseClick: (timeGrid: TimeGrid, row, col) => {
        if (state.reservedSchedules[row][col]) {
          emit("cancelReserve");
        }
        if (
          state.openedSchedule[row][col] &&
          !state.reservedSchedules[row][col]
        ) {
          emit("deleteSchedule", timeGrid, state.weekIndex);
          resetSelectedRange();
        }
      },
    };
    return {
      state,
      actions,
      monthType,
      weekType,
      yoil,
      isSelectedDate,
      moment,
      timeUnits,
      timeTableWrapperElement,
    };
  },
});
