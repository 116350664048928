
import { computed, defineComponent, PropType, reactive } from "vue";
import moment, { Moment } from "moment-timezone";

export default defineComponent({
  name: "WeekDateCell",
  props: {
    date: {
      type: Object as PropType<Moment>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      day: computed(() => {
        return props.date ? props.date.date() : "";
      }),
      yoilIndex: computed(() => {
        return props.date.day();
      }),
      isToday: computed(() => {
        return (
          props.date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        );
      }),
    });

    return { state };
  },
});
